<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
  >
    <template v-slot:accountId="{ row }">
      <router-link
        class="editable-item__link"
        :to="{ name: 'AccountEdit', params: { id: row.accountId } }"
      >
        {{ row.accountId }}
      </router-link>
    </template>
    <template v-slot:basePhonesId="{ row }">
      <router-link
        class="editable-item__link"
        :to="{ name: 'BasePhoneMatchingEdit', params: { id: row.basePhonesId } }"
      >
        {{ row.basePhonesId }}
      </router-link>
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'BasePhonesChangeLog',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'BasePhonesChangeLog',
      tableCaption: 'Журнал изменений соответствий баз и телефонов',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Журнал изменений соответствий баз и телефонов',
        },
      ],
      tableHeaders: [
        {
          text: 'ID измененной записи',
          alias: 'basePhonesId',
          order: 'basePhonesId',
        },
        {
          text: 'Дата и время изменений',
          alias: 'changeDate',
          order: 'changeDate',
        },
        {
          text: 'ID пользователя',
          alias: 'accountId',
          order: 'accountId',
        },
        {
          text: 'ФИО пользователя',
          alias: 'userFio',
          order: 'userFio',
        },
        {
          text: 'Действие',
          alias: 'action',
          order: 'action',
        },
        {
          text: 'Новый номер телефона',
          alias: 'newPhoneNumber',
          order: 'newPhoneNumber',
        },
        {
          text: 'Новое название базы',
          alias: 'newDatabaseName',
          order: 'newDatabaseName',
        },
        {
          text: 'Новый комментарий',
          alias: 'newComment',
          order: 'newComment',
        },
        {
          text: 'Новое количество параллельных линий',
          alias: 'newParallelLineCount',
          order: 'newParallelLineCount',
        },
      ]
    };
  },
};
</script>
